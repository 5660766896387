<template>
  <div>
    <MyHeader />
    <main id="content">
      <nav class="user-tab-nav">
        <div class="container">
          <ProfileMenu />
        </div>
      </nav>
      <div class="container mt-4 mt-lg-5">
        <BookingInfo />
        <div class="row mb-4 mb-lg-5">
          <div class="col-12">
            <UserTripMenu />
          </div>
        </div>
        <div class="row mb-5"
             v-if="booking">
          <div class="col-12 col-lg-6 pr-lg-5 mb-4">
            <h3 class="min-title">
              Booking Charges
            </h3>
            <hr>
            <div v-for="(charge, index) in charges"
                 :key="index">
              <ul class="charge-list"
                  v-if="charge.type === 1">
                <li class="d-flex justify-content-between">
                  {{ charge.description }} <strong>${{ charge.amount }}</strong>
                </li>
              </ul>
            </div>
            <hr>
            <div class="charge-total">
              Total <strong>${{ booking.amount }}</strong>
            </div>
            <hr>
            <p class="m-0"
               v-if="booking && booking.brand && booking.payment_reference_code">
              <strong>Paid with:</strong> <br> {{ booking.brand }} {{ booking.payment_reference_code }}
            </p>
          </div>
          <div class="col-12 col-lg-6 pl-lg-5 pt-3 pt-lg-0 border-top-down-md border-left-lg">
            <h3 class="min-title">
              Aditional Charges
            </h3>
            <hr v-if="aditionalChargesExists">
            <div v-for="(charge, index) in charges"
                 :key="index">
              <ul class="charge-list"
                  v-if="charge.type === 2">
                <li class="d-flex justify-content-between">
                  {{ charge.description }} <a v-if="charge.invoice && charge.invoice.path"
                                              :href="charge.invoice.path"
                                              target="_blank"> View Invoice</a>
                  <strong>${{ charge.amount }}</strong>
                </li>
              </ul>
            </div>
            <hr>
            <div class="charge-total">
              Total <strong>${{ totalChargesAditional }}</strong>
            </div>
            <hr>
            <div class="charge-total">
              Pending Charges <strong class="text-warning">${{ totalChargesPending > 0 ? totalChargesPending : 0 }}</strong>
            </div>
            <hr>
            <button class="btn btn-primary"
                    v-if="booking.status !== 3 && totalChargesPending > 0"
                    type="button"
                    @click="validateBookingRequest"
                    :disable="!paymentVerification">
              PAY NOW
            </button>
          </div>
          <PaymentVerificationForm :payment-verification="paymentVerification"
                                   :get-user="getUser" />
        </div>
      </div>
    </main>
    <MyFooter />
  </div>
</template>

<script lang="ts">
import ProfileMenu from '../../components/users/ProfileMenu.vue';
import UserTripMenu from '../../components/users/UserTripMenu.vue';
import BookingInfo from '../../components/users/BookingInfo.vue';
import PaymentVerificationForm from '../../components/PaymentVerificationForm.vue';

export default {
  name: 'UserMessageDetail',
  components: {
    ProfileMenu,
    UserTripMenu,
    BookingInfo,
    PaymentVerificationForm
  },
  data() {
    return {
      // booking: {},
      charges: [],
      verificationData: {},
      aditionalChargesExists: false,
      totalChargesAditional: 0,
      totalChargesPending: 0,
      user: {},
    };
  },
  methods: {
    validateBookingRequest() {
      this.$validator.validateAll().then((result) => {
        if (result) {
          AlignetVPOS2.openModal('https://vpayment.verifika.com/');
        }
      });
    },
  },
  mounted() {
    setTimeout(() => {
      const alignetsac = document.createElement('script');
      alignetsac.setAttribute('src', 'https://vpayment.verifika.com/VPOS2/js/modalcomercio.js');
      document.head.appendChild(alignetsac);
    }, 1000);
  },
  computed: {
    paymentVerification() {
      return this.verificationData;
    },
    getUser() {
      return this.user;
    },
    booking() {
      return this.$store.getters.getBookingTrip;
    }
  },
  created() {
    setTimeout(() => {
      const { status } = this.$store.getters.getBookingTrip;
      if (status === 2 || status === 5) {
        this.$router.push({ name: 'user-trip-overview', params: { id: this.$route.params.id } });
      } else if (!this.$route.params.id) {
        this.$router.push('/');
      }
    }, 500);
    this.user = this.$store.getters.getUser;
    // this.booking = JSON.parse(this.$localStorage.get('bookingTrip'));
    this.$axios.get(`/v1/bookings/${this.$route.params.id}/charges`).then((response) => {
      this.charges = response.data.data;
      for (let i = 0; i < this.charges.length; i += 1) {
        if (this.charges[i].type === 2) {
          this.totalChargesAditional += this.charges[i].amount;
          this.aditionalChargesExists = true;
        }
        if (this.charges[i].status === 0 && this.charges[i].type === 2) {
          this.totalChargesPending += this.charges[i].amount;
        }
      }
      this.$axios.get(`/v2/payments/verification/additionals?booking_id=${this.$route.params.id}`).then((bookingResponse) => {
        this.verificationData = bookingResponse.data.data;
      });
      Promise.resolve();
    }).catch(error => Promise.reject(error));
  },
};
</script>

<style lang="scss" scoped>

</style>
